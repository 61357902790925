import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import PropertiesTable from '../components/properties/PropertiesTable'
import Toolbar from '@mui/material/Toolbar'
import AddProperty from '../components/properties/addProperty'
import { useContext, useEffect, useState } from 'react'
import userEvent from '@testing-library/user-event'
import { UserContext } from '../App'

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Veerii LLC
//       </Link>
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   )
// }

export default function Home() {
  const { user } = useContext(UserContext)
  // fetch the properties data to populate the table
  const [propertiesData, setPropertiesData] = useState([])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/properties`, {
      method: 'GET',
      headers: {
        'Content-Type':'application/json',
        Authorization: 'Bearer ' + user.accessToken,
      }
    })
      .then(res => res.json())
      .then(data => {
        setPropertiesData(data)
      })
      .catch(err => console.error(err))
  }, [])


  return (
    <div>
      <Box
        component="main"
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container sx={{ mt: 4, mb: 4 }}>
          <Box>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <AddProperty />
              <PropertiesTable propertiesData={propertiesData} />
            </Paper>
          </Box>
          {/* <Copyright sx={{ pt: 4 }} /> */}
        </Container>
      </Box>
    </div>
  )
}
